import { h, Component } from 'preact';
import { Layout } from './lib/mdl';
import Sidebar from './components/sidebar';
import Header from './components/header';
import Main from './components/main';
import Auth from './components/auth';

export default class App extends Component {
	shouldComponentUpdate() { return false; }

	render() {
		return (
			<Layout fixed-header>
				<Header />
				<Sidebar />
				<Main />
				<Auth />
			</Layout>
		);
	}
}
