
let db = {
	ns: 'esbench',
	get(key) {
		if (typeof window === 'undefined') return;
		let p = localStorage.getItem(`${db.ns}.${key}`);
		if (p) return JSON.parse(p);
	},
	set(key, value) {
		if (typeof window === 'undefined') return;
		localStorage.setItem(`${db.ns}.${key}`, JSON.stringify(value));
	}
};

if (typeof window === 'undefined') {
	const data = {};
	db.get = key => data[key];
	db.set = (key, value) => { data[key] = value; };
}

export default db;
