
let opt = { style:null },
	current;

addEventListener('mousedown', md, true);
addEventListener('touchstart', md, true);
addEventListener('mouseover', hover, true);

function hover(e) {
	let el = e.touches && e.touches[0].target || e.target,
		inkParent = el && getInkParent(el);
	if (inkParent && inkParent!==current) {
		if (current) current.removeAttribute('ink-enabled');
		current = inkParent;
		if (!inkParent.hasAttribute('ink-enabled')) {
			inkParent.setAttribute('ink-enabled', 'true');
		}
	}
}

export function md(e) {
	let el = e.touches && e.touches[0].target || e.target,
		x = e.touches && e.touches[0].pageX || e.pageX,
		y = e.touches && e.touches[0].pageY || e.pageY,
		inkParent = getInkParent(el, opt);

	if (!inkParent) return;

	let p = inkParent;
	do {
		x -= (p.offsetLeft || 0) - (p.scrollLeft || 0);
		y -= (p.offsetTop || 0) - (p.scrollTop || 0);
	} while ( (p=p.offsetParent) && p!==document.body );

	let ax = x - (inkParent.offsetWidth/2),
		ay = y - (inkParent.offsetHeight/2);

	if (!inkParent.hasAttribute('ink-enabled')) {
		inkParent.setAttribute('ink-enabled', 'true');
	}

	let ox = parseFloat(opt.style.width)/2 + ax,
		oy = parseFloat(opt.style.height)/2 + ay;

	inkParent.style.transformOrigin = inkParent.style.mozTransformOrigin = inkParent.style.webkitTransformOrigin = `${ox}px ${oy}px`;
}


function getInkParent(el, opt) {
	while (el.nodeType===3) el = el.parentNode;
	if (!isRootNode(el)) {
		do {
			let computedStyle = window.getComputedStyle(el, ':before');
			if (computedStyle.color==='rgba(0, 0, 1, 0)') {
				if (opt) opt.style = computedStyle;
				return el;
			}
		} while ( (el=el.parentNode) && !isRootNode(el) );
	}
	return false;
}

function isRootNode(node) {
	return node===document.body || node===document.documentElement || node===document;
}
