
		import Async from '@preact/async-loader/async';

		function load(cb) {
			require.ensure([], function (require) {
				cb( require("!!../../node_modules/babel-loader/lib/index.js??ref--4!./search.js") );
			}, "route-search");
		}

		export default Async(load);
	