import { h, Component } from 'preact';
import { Layout, Navigation } from '../lib/mdl';

export default class Sidebar extends Component {
	shouldComponentUpdate() { return false; }

	close = () => {
		this.base.classList.remove('is-visible');
	}

	render() {
		return (
			<Layout.Drawer onclick={ this.close }>
				<Layout.Title>
					<Navigation.Link href="/" class="ink ink-dark">ESBench</Navigation.Link>
				</Layout.Title>
				<Navigation>
					<Navigation.Link href="/tags" class="ink ink-dark">Tags</Navigation.Link>
					<Navigation.Link href="/bench" class="ink ink-dark">New Bench</Navigation.Link>
					<Navigation.Link href="/profile" class="ink ink-dark">Profile</Navigation.Link>
				</Navigation>
			</Layout.Drawer>
		);
	}
}
