import resource from './resource';
import db from './db';
import pubsub from '../pubsub';

const IS_DEV = typeof localStorage !== 'undefined' && localStorage.ENV==='dev';
const ORIGIN = IS_DEV ? 'http://localhost:9002' : 'https://api.esbench.com';		// https://esbench.herokuapp.com

let esbench = resource(ORIGIN, ['users', 'sessions', 'benches', 'tags']);

esbench.header('accept', 'application/json');

esbench.on('session', session => pubsub.emit('authchange', session && session.user));

// persist session and automatically set a global Authorization header
esbench.setSession = (session = false) => {
	let { token } = session;

	esbench.session = session;
	if (session === false) delete esbench.session;

	db.set('session', session);
	esbench.header('authorization', token ? `Bearer ${token}` : false);

	esbench.emit('session', session);
	esbench.emit(session ? 'login' : 'logout', session);
};

esbench.sessions.on('res', ({ method }, { status, data }) => {
	if (method === 'POST' && data.token) {
		esbench.setSession(data);
	} else if (method === 'DELETE' && ((status / 100) | 0) === 2) {
		esbench.setSession(false);
	}
});

esbench.on('status:401', (req, res) => {
	if (!esbench.session) {
		pubsub.emit('attempt-login');
	}
});

// restore persisted session
esbench.setSession(db.get('session'));

// check session and update stored user
if (esbench.session) {
	esbench.users.get('me', (err, data, { status }) => {
		if (data && data.username) {
			esbench.session.user = data;
			db.set('session', esbench.session);
		}
		if (status === 401 || status === 403) {
			console.log('Session check failed, downgrading session');
			esbench.setSession(false);
		}
	});
}

// expose globally in dev mode:
if (typeof localStorage !== 'undefined' && localStorage.ENV === 'dev') {
	window.esbench = esbench;
}

export default esbench;
