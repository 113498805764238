import { h, Component } from 'preact';
import { Layout, Button, Icon } from '../lib/mdl';
import { Router, route } from 'preact-router';
import Index from '../routes/index';
import Bench from '../routes/bench';
import Profile from '../routes/profile';
import Tags from '../routes/tags';
import Search from '../routes/search';
import pubsub from '../pubsub';

export default class Main extends Component {
	shouldComponentUpdate() { return false; }

	routeChanged = ({ url }) => {
		this.updateUrl(url);
	}

	componentDidMount() {
		this.updateUrl(this.url);
	}

	updateUrl(url) {
		this.url = url;
		let onBenchView = !!url.match(/^\/?bench(\/|$)/);
		if (this.base) {
			this.base.querySelector('.global-new').classList[onBenchView?'add':'remove']('is-hidden');
		}
		pubsub.emit('url-changed');

		if (typeof ga==='function') {
			ga('send', 'pageview', url);
		}
	}

	render() {
		return (
			<Layout.Content id="app">
				<Router onChange={ this.routeChanged }>
					<Index path="/" />
					<Bench path="/bench/:id?" />
					<Profile path="/profile/:user?" />
					<Tags path="/tags" />
					<Search path="/tag/:tag" />
					<Search path="/search/:tag" />
				</Router>

				<Button component="a" href="/bench" fab colored class="global-new">
					<Icon>create</Icon>
				</Button>
			</Layout.Content>
		);
	}
}
