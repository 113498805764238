import sleeper from 'sleeper';

export default function resource(res, children=res.children) {
	let r = sleeper(res.url || res.name || res);
	r._children = [];
	r.param = resource.proxy('param');
	r.header = resource.proxy('header');
	r.on = r.addListener = resource.proxy('on');
	r.removeListener = resource.proxy('removeListener');
	if (Array.isArray(children)) {
		children.forEach( c => resource.child(r, c) );
	}
	return r;
}

resource.child = (res, child) => {
	if (typeof child==='string') child = { name:child };
	child.url = res.url.replace(/\/$/,'') + '/' + (child.url || child.name);
	let r = resource(child);
	res._children.push(r);
	return res[child.name] = r;
};

resource.proxy = name => function (...args) {
	this._children.forEach( api => api[name](...args) );
	return this.constructor.prototype[name].apply(this, args);
};
