import { h, Component } from 'preact';
import { Card, Tabs, Button, TextField } from '../lib/mdl';
import esbench from '../models/esbench';
import pubsub from '../pubsub';
import linkState from 'linkstate';
import cx from 'clsx';

export default class Auth extends Component {
	componentDidMount() {
		pubsub.on('attempt-login', this.show);
		pubsub.on('login-success', this.hide);
	}

	show = () => {
		this.setState({ show: true });
	}

	hide = () => {
		this.setState({ show: false });
	}

	render(props, state) {
		let showing = state.show || props.show;
		return (
			<div class={cx('auth', showing && 'showing')} onClick={ e => (e.target===e.currentTarget?this.hide():null) }>
				<Card class="dialog" shadow="4">
					<Tabs>
						<Tabs.Bar>
							<Tabs.Tab href="#auth-login" active>Log In</Tabs.Tab>
							<Tabs.Tab href="#auth-register">Register</Tabs.Tab>
						</Tabs.Bar>
						<Tabs.Panel id="auth-login" active>
							<LoginForm />
						</Tabs.Panel>
						<Tabs.Panel id="auth-register">
							<RegisterForm />
						</Tabs.Panel>
					</Tabs>
				</Card>
			</div>
		);
	}
}


export class LoginForm extends Component {
	submit = (e) => {
		let { username, password } = this.state;
		this.setState({ loading:true });
		esbench.sessions.create({
			username, password
		}, (err, data={}) => {
			this.setState({ loading:false });

			let error = data.error || data.message || err;
			if (error) return this.setState({ error });

			pubsub.emit('login-success');
		});

		if (e && e.stopPropagation) e.stopPropagation();
		if (e && e.preventDefault) e.preventDefault();
		return false;
	}

	render({}, { username, password, error, loading }) {
		return (
			<form class={cx('login-form', loading && 'loading')} action="javascript:" onSubmit={this.submit}>
				<div>{ error ? (<div class="error">{error}</div>) : null }</div>

				<TextField
					class="username"
					label="Username / Email"
					pattern="^(^[a-z0-9._-]{3,}|\w+@\w+.\w{2,})$"
					floating-label
					onChange={ linkState(this, 'username') }
					onInput={ linkState(this, 'username') }
					value={ username } />

				<TextField
					class="password"
					type="password"
					label="Password"
					floating-label
					onChange={ linkState(this, 'password') }
					onInput={ linkState(this, 'password') }
					value={ password } />

				<button-bar>
					<Button onClick={this.submit}>Log In</Button>
				</button-bar>
			</form>
		);
	}
}




export class RegisterForm extends Component {
	submit = () => {
		let { username, email, password } = this.state;
		esbench.users.create({
			username, email, password
		}, (err, data={}) => {
			let error = data.error || data.message || err;
			if (error) return this.setState({ error });

			esbench.sessions.create({
				email, password
			}, (err, data={}) => {
				let error = data.error || data.message || err;
				if (error) return this.setState({ error:`Login error: ${err}` });

				console.log({ error, data });
				pubsub.emit('login-success');
			});
		});
	}

	render({}, { email, username, password, error }) {
		return (
			<div class="register-form">
				<div>{ error ? (<div class="error">{error}</div>) : null }</div>

				<TextField
					id="email"
					type="email"
					label="Email"
					floating-label
					onChange={ linkState(this, 'email') }
					onInput={ linkState(this, 'email') }
					value={ email } />

				<TextField
					id="username"
					pattern="^(^[a-z0-9._-]{3,}|\w+@\w+.\w{2,})$"
					label="Username"
					floating-label
					onChange={ linkState(this, 'username') }
					onInput={ linkState(this, 'username') }
					value={ username } />

				<TextField
					id="password"
					type="password"
					label="Password"
					floating-label
					onChange={ linkState(this, 'password') }
					onInput={ linkState(this, 'password') }
					value={ password } />

				<button-bar>
					<Button onClick={ this.submit }>Register</Button>
				</button-bar>
			</div>
		);
	}
}
