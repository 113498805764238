import { Card, Button } from '../lib/mdl';

export default function Index() {
	return (
		<div id="view-index" class="view hero-bg">
			<h1>Shareable<br />Modern JavaScript<br />Benchmarking</h1>

			<Card shadow="4" class="centered">
				<Card.Text>
					<img class="logo" src="/assets/icon-256.png" />

					<ul class="home-list">
						<li>
							<strong>Modern JS transpilation</strong>
							<br />
							<em>powered by <a href="https://babeljs.io" target="_blank" rel="noopener nofollow">Babel</a></em>
						</li>
						<li>
							<strong>Statistically significant results</strong>
							<br />
							<em>powered by <a href="https://benchmarkjs.com" target="_blank" rel="noopener nofollow">Benchmark.js</a></em>
						</li>
						<li>
							<strong>Import and test npm modules</strong>
							<br />
							<em>powered by <a href="https://unpkg.com" target="_blank" rel="noopener nofollow">Unpkg.com</a></em>
						</li>
					</ul>
				</Card.Text>

				<Card.Actions class="centered">
					<Button component="a" href="/bench" raised accent>New Bench</Button>
				</Card.Actions>
			</Card>
		</div>
	);
}
