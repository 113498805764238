import { h, Component } from 'preact';
import { Layout, Navigation } from '../lib/mdl';
import { route } from 'preact-router';
import esbench from '../models/esbench';
import pubsub from '../pubsub';

export default class Header extends Component {
	shouldComponentUpdate() { return false; }

	componentDidMount() {
		this.setLoggedIn(!!esbench.session);
		esbench.on('session', session => this.setLoggedIn(!!session) );

		const updateSeamless = () => {
			let isCardBase = document.querySelector('#app > .seamless, #app > .seamless');
			this.base.classList[isCardBase?'add':'remove']('seamless');
		};

		let timer, timer2;
		pubsub.on('url-changed', () => {
			clearTimeout(timer);
			clearTimeout(timer2);
			setTimeout(updateSeamless, 1);
			timer = setTimeout(updateSeamless, 100);
			timer2 = setTimeout(updateSeamless, 1000);
		});
	}

	login() {
		pubsub.emit('attempt-login');
	}

	setLoggedIn(loggedin) {
		let link = this.base.querySelector('.login-link');
		link.style.display = loggedin ? 'none' : '';
	}

	render() {
		return (
			<Layout.Header>
				<Layout.HeaderRow>

					<Layout.Title>
						<a class="logotype" style="cursor:pointer" href="/">ESBench</a>
					</Layout.Title>

					<Layout.Spacer />

					<Navigation large-screen-only>
						<Navigation.Link {...{route}} href="/" class="ink">Home</Navigation.Link>
						<Navigation.Link {...{route}} href="/profile" class="ink">Profile</Navigation.Link>
						<Navigation.Link class="login-link ink" style={{ display: esbench.session?'':'none' }} onclick={ this.login }>Log In</Navigation.Link>
					</Navigation>

				</Layout.HeaderRow>
			</Layout.Header>
		);
	}
}
